import React, { useEffect, useState, useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import CaseStudyBody from '../../components/pages/caseStudy/CaseStudyBody';
import { ICasyStudy } from '../../apiTypes';
import { client } from '../../utils/client';

const query = `*[_type == "work" && slug.current == $slug]{
    title,
    clientName,
    seoTitle,
    seoDescription,
    awardsTitle,
    "mainImage": {
      "asset": {
          "fluid": {
              "src": mainImage.asset->url,
              "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
          }
      }
    },
    "mainVideo": {
      "asset": {
        "url": mainVideo.asset->url
      }
    },
    tag[]->{
      name
    },
    works[]->{
        clientName,
        title,
        slug,
        darkBackground,
        "mainImage": {
            "asset": {
                "fluid": {
                    "src": mainImage.asset->url,
                    "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
                }
            }
        },
    },
    infoBanner,
    sections[]{
      _key,
      _type,
      heading,
      "_rawBody": body,
      text,
      awardsList,
      "mainImage": {
        "asset": {
          "fluid": {
            "src": mainImage.asset->url,
            "aspectRatio": mainImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
      "firstImage": {
        "asset": {
          "fluid": {
            "src": firstImage.asset->url,
            "aspectRatio": firstImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
      "secondImage": {
        "asset": {
          "fluid": {
            "src": secondImage.asset->url,
            "aspectRatio": secondImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
      "placeholderImage": {
        "asset": {
          "fluid": {
            "src": placeholderImage.asset->url,
            "aspectRatio": placeholderImage.asset->metadata.dimensions.aspectRatio
          }
        }
      },
      "video": {
        "asset": {
          "url": video.asset->url
        }
      },
      videoUrl
    },
  }`;

const PreviewCaseStudy = (): React.ReactNode => {
  const [loading, setLoading] = useState(true);
  const [caseStudy, setCaseStudy] = useState({} as ICasyStudy);
  const location = useLocation();

  const slug = useMemo(() => queryString.parse(location.search).slug, [
    location,
  ]);

  const fetchData = useCallback(async () => {
    const data: ICasyStudy[] = await client.fetch(query, { slug });
    setCaseStudy(data[data.length - 1]);
    setLoading(false);
  }, [slug]);

  useEffect(() => {
    const subscribeData = client
      .listen(query, { slug }, { visibility: 'query', includeResult: false })
      .subscribe(() => {
        setLoading(true);
        fetchData();
      });

    return () => subscribeData.unsubscribe();
  }, [fetchData, slug]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading && !caseStudy) {
    return <div>Loading ...</div>;
  }

  return <CaseStudyBody data={{ caseStudy }} />;
};

export default PreviewCaseStudy;
